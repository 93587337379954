import * as React from 'react';
import styled from 'styled-components';
import { Container } from '../../layout/Container';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Section } from '../../layout/Section';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../utils/seo/Index';
import { animated } from 'react-spring';
import { Hero, HeroContent } from '../../ui/hero/Index';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { routeConfig } from '../../../__config__/routes';
import CustomerAdvice from './CustomerAdvice';
import { Logo } from '../../ui/logo/office/Index';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { useMutation } from '@apollo/client';
import { useForm } from '../../../utils/hooks/useForm';
import { Form, FormRow } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import Ads from './Ads';
import Card from './Card/Card';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';
import { MAIL_MUTATION } from '../../../../client/__graphql__/mutations/mail';
import { Textarea } from '../../ui/form/Textarea';
import { useScrollToElement } from '../../../utils/hooks/useScrollToElement';
import { GENERAL_VALUATION_MUTATION } from '../../../../client/__graphql__/mutations/valuation';
import { createGTMEvent } from '../../../utils/tracking/gtm';
import { LeadType } from '../../../__types__/generated/globalTypes';
import { PageType } from '../../../__types__/generated/globalTypes';
import { Thanks } from '../../layout/Thanks';
import { NybyggProperties } from './NybyggProperties';
import { useDigtectiveContext } from '../../../__lib__/digtective/context/hooks/useDigtectiveContext';
import NybyggInfo from './NybyggInfo';
import { FinanceCard } from './FinanceCard';
import NybyggCalculator from './Calc/NybyggCalculator';
import Playlist from './Playlist';

const FinancingNybyggPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [posted, setPosted] = React.useState(false);
  const contactRef = React.useRef<null | HTMLDivElement>(null);
  const { scrollTo } = useScrollToElement();
  const { submitWithDigger } = useDigtectiveContext();

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const [mail, { loading: mailLoading, error }] = useMutation<
    ILeadResponse,
    ILeadInput
  >(MAIL_MUTATION, {
    onCompleted: ({ lead }) => {
      if (lead.mail?.success) {
        setPosted(true);
      }
    }
  });

  const [lead, { error: leadError, loading: leadLoading }] = useMutation(
    GENERAL_VALUATION_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (lead.valuation?.success) {
          setPosted(true);

          // GTM tracking
          createGTMEvent({
            event: 'pmFormSubmission',
            gaEvent: 'RT_VALUATION',
            gaCategory: 'RT_VALUATION',
            gaAction: 'RT_SEND_VALUATION'
          });
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Eks. Ola Nordmann',
        value: '',
        label: 'Navn *',
        required: true
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'textarea',
        name: 'comment',
        label: 'Beskjed *',
        labelAfter: '(Din beskjed her)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      submitWithDigger({ zip: preparedFields.zip }, ({ diggerId }) => {
        lead({
          variables: {
            input: {
              ...preparedFields,
              diggerId,
              referer: `${location.pathname}${location.search}`,
              leadType: LeadType.VERDIVURDERING,
              pageType: PageType.GENERELL
            }
          }
        });
      });
    }
  });

  return (
    <>
      <Seo
        title="Finansering - PrivatMegleren - Nordeas Eiendomsmeglerkjede"
        description="Finansiering av nybygg. Snakk med oss om Boliglån."
        imageTitle="/Boliglån-finansiering-nybygg"
      />
      <Wrapper>
        <Hero
          className="content-height padding-top-150 padding-bottom-80"
          size="cover"
          position="bottom"
          imageUrl="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/bg-nybygg.jpg"
          opacity={0.6}
          alt="bilde"
          imagePosition="0% 60%"
        >
          <HeroContent className="title">
            <Heading tag="h1" center={true}>
              Finansiering av Nybygg
            </Heading>
          </HeroContent>
        </Hero>
      </Wrapper>
      <Container>
        <Heading style={{ paddingBottom: '0.5em' }} center tag="h3">
          Nyttig å vite om finansiering av nybygg{' '}
        </Heading>
        <NybyggInfo />
        <Grid className="grid top">
          <GridColumn id="item1">
            <FinanceCard />
          </GridColumn>
          <GridColumn id="item2">
            <NybyggCalculator />
          </GridColumn>
        </Grid>
        <CustomerAdvice />
      </Container>
      <Container style={{ paddingTop: '50px' }}>
        <Section style={pageFadeIn}>
          <Heading center tag="h3">
            Mer om finansiering - lytt til Nordeas Podcast
          </Heading>
          <Paragraph center style={{ paddingBottom: '2em' }}>
            Vil du vite mer om finansiering og hva som lønner seg ved kjøp og
            salg av bolig? Ta en lytt til Nordea’s podcast. Under kan du høre
            noen utvalgte episoder som handler om nettopp finansiering og
            eiendom.
          </Paragraph>
          <Playlist />
          <LogoWrapper>
            <Logo
              className="logo"
              alt="bilde av logo"
              style={{ margin: '5em auto' }}
            />
          </LogoWrapper>
          <Heading center tag="h3">
            PrivatMegleren i ditt nærområde
          </Heading>
          <Paragraph center>
            Utstyrt med markedets mest innovative løsninger, sammen med en
            dyptgående og grunnleggende tilnærming, er målet vårt å få flest
            mulig budgivere på enhver eiendom vi omsetter. Vi kjenner
            boligmarkedet til enhver tid. Vårt nettverk, erfaring og
            skreddersydde leveranse med optimal håndtering av alle
            interessenter, sikrer deg et godt salg og et riktig kjøp av eiendom.
          </Paragraph>
          <Paragraph center>
            <a
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                if (typeof scrollTo === 'function') {
                  scrollTo({
                    ref: contactRef,
                    distanceTop: 100,
                    behaviour: 'smooth'
                  });
                }
              }}
            >
              Kontakt oss
            </a>{' '}
            gjerne dersom du ønsker å vite mer om hvordan din eiendom vil
            fremstå gjennom PrivatMegleren.
          </Paragraph>
          <Heading center tag="h3">
            Eiendommer til salgs
          </Heading>
          <Paragraph style={{ paddingBottom: '1em' }} center>
            Vurderer du å kjøpe en{' '}
            <a href="https://privatmegleren.no/kjope-bolig?a=true&c=null&l=%5B%5D&mb=0&mv=false&p=1&pfv=1000000&ptv=25000000&q=&s=false&sd=BRUKT&sfv=30&show_market_link=false&stv=400">
              brukt bolig
            </a>
            ,{' '}
            <a href="https://privatmegleren.no/kjope-bolig?a=true&c=null&l=%5B%5D&mb=0&mv=false&p=1&pfv=1000000&ptv=25000000&q=&s=false&sd=NYBYGG&sfv=30&show_market_link=false&stv=400">
              nybygg
            </a>{' '}
            eller <a href="https://privatmegleren.no/fritid">fritidseiendom</a>?
            Vi gir deg gjerne veiledning og tips om hvordan du kan utnytte din
            økonomi på best mulig måte.
          </Paragraph>
        </Section>
      </Container>
      <NybyggProperties />
      <Container>
        <Ads>
          <Card
            description="La oss hjelpe deg til et bedre eiendomskjøp<br/>Les mer om kjøpsmegling."
            logo="https://cdn.reeltime.no/pm_assets/img/cards/kjop_logo.png"
            background="https://cdn.reeltime.no/pm_assets/img/cards/kjop_bg.png"
            link="https://privatmegleren.no/kjop"
          />
          <Card
            background="https://cdn.reeltime.no/pm_assets/img/cards/art.jpg"
            button
            buttonText="Finn kontor"
            link="https://privatmegleren.no/kontorer?alle"
            title="Vurderer du salg<br/>av eiendom?"
            className="card-split"
          />
          <Card
            background="https://cdn.reeltime.no/pm_assets/img/cards/stairs.png"
            button
            buttonText="Søk adresse"
            link="https://privatmegleren.no/verdivurdering-boligkalkulator"
            title="PrivatMegleren<br/>Verdi"
            description="La oss gi deg et estimat på hva eiendommen er verdt."
          />
        </Ads>
        <FormWrapper ref={contactRef} style={fadeOut}>
          <Form
            onSubmit={handleSubmit}
            noValidate
            style={{ paddingBottom: '50px' }}
          >
            <Heading
              style={{ textAlign: 'center', paddingTop: '2em' }}
              tag="h2"
            >
              Bli kontaktet
            </Heading>
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}

            {fields
              .filter((item) => item?.type !== 'textarea')
              .map((item, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Input
                      type={item?.type}
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
            {fields
              .filter((item) => item?.type === 'textarea')
              .map((item, index) => {
                return (
                  <FormRow
                    className="span questionTextArea"
                    key={`field_${index}`}
                  >
                    <Textarea
                      rows={4}
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
            <ButtonGroup>
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Send
              </Button>
              <ButtonLink
                as="a"
                href={routeConfig.personvern.url}
                className="privacy-link-mobile"
              >
                Personvernpolicy
              </ButtonLink>
            </ButtonGroup>
          </Form>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h2" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
          </Section>
        </Thanks>
      </Container>
    </>
  );
};

export default FinancingNybyggPage;

const Wrapper = styled.div`
  .content-height {
    height: auto;
    min-height: 307px;
  }
`;

const GridColumn = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  margin-top: 2em;
  margin-bottom: 2em;

  @media all and (max-width: 400px) {
    > div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (min-width: 46rem) {
    grid-template-columns: repeat(2, 1fr);A
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);

    #item1 {
      grid-column: span 2;
    }

    #item2 {
      grid-column: span 1;
    }
  }

  &.top {
    align-items: flex-start;
  }

  .subtitle {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: -15px;
    font-weight: bold;
    font-size: 1.2rem;
  }
`;

const FormWrapper = styled(animated.div)`
  grid-column: span 2;
  .questionTextArea {
    grid-column: span 2;
  }
  textarea {
  }
  .questionForm {
    gap: 1em;
    .raagiving-gruppe {
      grid-column: span 2;
    }

    @media all and (min-width: 600px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const LogoWrapper = styled.div`
  .logo {
    width: 100%;
    height: 70px;
  }
`;
