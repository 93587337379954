import * as React from 'react';
import styled from 'styled-components';
import { routeConfig } from '../../../__config__/routes';
import { Button } from '../../ui/button/Button';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';

export const FinanceCard = () => {
  return (
    <FinanceCardContainer>
      <Heading center tag="h3">
        Spørsmål om finansiering?
      </Heading>
      <Paragraph style={{ padding: '0 1.5rem' }} center>
        Lurer du på noe rundt finansieringsbevis, forskuddsbetaling eller
        mellomfinansiering?
      </Paragraph>
      <Paragraph style={{ padding: '0 1.5rem' }} center>
        PrivatMegleren eies av Nordea, og kan hjelpe deg med et skreddersydd
        tilbud som passer dine behov.
      </Paragraph>
      <ButtonGroup style={{ justifyContent: 'center', minWidth: '240px' }}>
        <Button
          className="button"
          type="primary"
          colorTheme="gold"
          as="a"
          href={routeConfig?.nordea_finansiering_nybygg.url}
          target="_blank"
        >
          Kontakt en Nordea-rådgiver
        </Button>
      </ButtonGroup>
    </FinanceCardContainer>
  );
};

const FinanceCardContainer = styled.div`
  background-color: #202020;
  padding: 0 2rem 2rem 2rem;
  box-shadow: 10px 4px 10px rgba(0, 0, 0, 0.2);

  .buttongroup {
    min-width: 240px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .paragraph {
    width: 100%;
    max-width: 30em;
  }
  .button {
    text-align: center;
  }
`;
