import React from 'react';
import { Paragraph } from '../../ui/text/Paragraph';
import styled from 'styled-components';

const NybyggInfo: React.FC = () => {
  return (
    <Wrapper>
      <Paragraph center>
        Å kjøpe nybygg skiller seg fra å kjøpe bruktbolig. Med nybygg får du
        lavere omkostninger, mulighet for å tilpasse boligen etter egne ønsker,
        og en mer forutsigbar kjøpsprosess uten budrunde. Det finnes likevel
        noen egne finansielle krav for nybygg. Det er derfor lurt å være kjent
        med disse for å sikre at du har full kontroll på kostnadene.
      </Paragraph>
      <Paragraph center>
        Nybygg innebærer ofte at du må betale en del av kjøpesummen på forhånd,
        vanligvis 10 %. Dette krever at du har finansieringsbevis klart, og ved
        behov for mellomfinansiering kan en ny verdivurdering av din
        eksisterende bolig være nødvendig. Husk også at eventuelle tilvalg, som
        endringer i innredning og materialer, ikke dekkes i
        finansieringssøknaden og må finansieres med fri egenkapital eller
        mellomfinansiering hvis mulig.
      </Paragraph>
      <Paragraph center>
        Vi anbefaler å kontakte en av våre prosjektmeglere tidlig for å sikre at
        finansieringen dekker alle kostnader knyttet til boligen du er
        interessert i.
      </Paragraph>
    </Wrapper>
  );
};

export default NybyggInfo;

const Wrapper = styled.div`
  padding: 0 2em;
`;