import * as React from 'react';
import styled from 'styled-components';
import { PropertyCard } from '../../ui/cards/cluster/Property';
import { useQuery } from '@apollo/client';
import { SEARCH_ESTATE } from '../../../../client/__graphql__/cluster/queries/search/estate';
import { client } from '../../../__graphql__/clientCluster';
import { ENV_CONFIG } from '../../../__config__/env';
import {
  AssignmentType,
  Estate,
  SearchEstateQuery,
  SearchEstateQueryVariables,
  SearchEstateSort
} from '../../../__types__/generated/cluster';
import { Pagination } from '../../ui/pagination/Pagination';
import { useScrollToElement } from '../../../utils/hooks/useScrollToElement';

export const NybyggProperties: React.FC = () => {
  const [pageIndex, SetCurrentPageIndex] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [isInit, setInit] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [list, setList] = React.useState<Partial<Estate>[]>([]);
  const ref = React.useRef<null | HTMLDivElement>(null);
  const { scrollTo } = useScrollToElement();

  // TODO legg til riktig assignmentTypes i filter : saleType: ['FRITID', 'BRUKT', 'NYBYGG']
  const { data, loading } = useQuery<
    SearchEstateQuery,
    SearchEstateQueryVariables
  >(SEARCH_ESTATE, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      input: { brandId: ENV_CONFIG?.BRAND_ID },
      filter: {
        isProjectUnit: false,
        published: true,
        assignmentTypes: [
          AssignmentType.NewDevelopment,
          AssignmentType.NewDevelopmentCommercial,
          AssignmentType.NewDevelopmentLeisure
        ],
        pagination: { page: pageIndex, limit: limit },
        sort: SearchEstateSort.PublishedNewOld
      }
    },
    onCompleted: ({ search }) => {
      setList(search?.estate?.list ?? []);
      setTotal(search?.estate?.total ?? 0);
      setInit(false);
    }
  });
  if (isInit || (!list?.length && loading)) return null;
  if (!list?.length) {
    return (
      <PropertiesWrapper className="no-result">
        <Message>
          <p>Ingen eiendommer funnet..</p>
        </Message>
      </PropertiesWrapper>
    );
  }

  return (
    <>
      <PropertiesWrapper ref={ref}>
        {list?.map((item, index) => (
          <PropertyCard
            className="propertycard"
            key={`property-${item?.estateId}-${index}`}
            item={item}
            currentPage={1}
          />
        ))}
      </PropertiesWrapper>
      <PaginationWrapper>
        <Pagination
          className="pagination"
          center
          currentPage={pageIndex}
          maxPages={total / limit}
          total={total}
          itemsPerPage={limit}
          pageRangeDisplayed={1}
          pageOnChange={({ selected }) => {
            if (typeof scrollTo === 'function') {
              scrollTo({
                ref: ref,
                distanceTop: 100,
                behaviour: 'smooth'
              });
            }
            if (selected !== pageIndex) {
              SetCurrentPageIndex(selected);
            }
          }}
        />
      </PaginationWrapper>
    </>
  );
};

const PropertiesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  width: 100%;
  max-width: 90em;
  margin: 0 auto;
  padding: 0 2rem;

  &.no-result {
    grid-template-columns: 1fr;
  }

  @media all and(max-width: 600px) {
    grid-template-columns: 1fr;
  }

  @media all and (min-width: 600px) and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2em;
  }

  @media all and (min-width: 1000px) and (max-width: 1380px) {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 2em;
  }

  @media all and (min-width: 1380px) {
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 2em;
  }
`;

const PaginationWrapper = styled.div`
  .pagination {
    padding: 2em 0 2em;
  }
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5em 0;
`;
