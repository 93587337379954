import React, { useRef } from 'react';
import styled from 'styled-components';
import { OpenButton } from './OpenButton';

//GraphQL
import { Paragraph } from '../../ui/text/Paragraph';

export interface IAreaItem {
  id: number;
  name: string | string[];
  selected: boolean;
}

export const AreaRowItem: React.FC<{
  question: string;
  answer: string | string[];
}> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [cssChange, setCssChange] = React.useState(false);

  const onClick = () => {
    if (!isOpen) {
      onOpenClose(!isOpen);
    } else {
      onOpenClose(!isOpen);
    }
  };

  const onOpenClose = (open: boolean) => {
    if (open) {
      setIsOpen(true);
      setTimeout(() => {
        setCssChange(true);
      }, 100);
    } else {
      setCssChange(!isOpen);
      setTimeout(() => {
        setIsOpen(!isOpen);
      }, 1100);
    }
  };

  return (
    <>
      <AreaRow
        onClick={() => {
          onClick();
        }}
      >
        <div className="area-name">{question}</div>
        <OpenButton
          width={25}
          height={25}
          className="button"
          isPositive={!(isOpen && cssChange)}
        />
      </AreaRow>
      <Container className={cssChange ? 'open' : ''}>
        <InfoItem>
          {Array.isArray(answer) ? (
            answer.map((paragraph, index) => (
              <StyledParagraph key={index}>{paragraph}</StyledParagraph>
            ))
          ) : (
            <Paragraph center>{answer}</Paragraph>
          )}
        </InfoItem>
      </Container>
    </>
  );
};

const StyledParagraph = styled.div`
  text-align: center;
  line-height: 1.4;
  color: #fff;

  &.form-consent-text {
    font-size: 0.875rem;
  }

  &:not(:first-child) {
    margin-top: 1rem; 
  }
`;

const AreaRow = styled.div`
  width: 100%;
  color: white;
  font-size: 22px;
  cursor: pointer;
  display: flex;
  position: relative;
  padding: 0.5em 0;
  align-items: center;
  .button {
    position: absolute;
    right: 0;
    display: flex;
  }
  border-bottom: 1px #4b4b4b solid;
  .area-name {
    @media all and (max-width: 450px) {
      max-width: 250px;
      word-break: break-word;
    }
  }
`;

const Container = styled.div`
  max-height: 0px;
  opacity: 0;
  transition: opacity 1s ease-in-out, max-height 0.8s ease-in-out;
  overflow: hidden;

  &.open {
    max-height: 100vh;
    opacity: 1;
  }
`;

const InfoItem = styled.div`
  width: 100%;
  padding: 1em 1em 2em 1em;
  justify-content: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
