import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../ui/text/Heading';
import { AreaRowItem } from './AreaRowItem';

const CustomerAdviceStyle = styled.div``;

const CustomerAdvice: React.FC = () => {
  const questions = [
    {
      question: 'Hva er fordelene med å kjøpe nybygg?',
      answer: [
        'Å velge nybygg gir deg en rekke fordeler som gjør boligkjøpet både enklere og mer forutsigbart. Først og fremst slipper du stressende budrunder - nye boliger selges ofte til fast pris, så du vet nøyaktig hva du må forholde deg til. Når du flytter inn, kan du nyte en bolig uten oppussingsbehov og uten skjulte overraskelser som fuktskader eller gamle elektriske anlegg.',
        'Hvis du kjøper tidlig i byggeprosessen, får du dessuten muligheten til å tilpasse boligen etter din egen smak gjennom tilvalg. I tillegg oppfyller nye boliger dagens strenge krav til materialer og byggemetoder, som gir deg et sunnere inneklima, bedre isolasjon og høyere kvalitet. Kostnadene ved kjøp av nybygg er også lavere enn for bruktboliger - du betaler kun 2,5 % dokumentavgift av tomteverdien, ikke av hele kjøpesummen.'
      ]
    },
    {
      question: 'Når må jeg betale hvis jeg kjøper nybygg?',
      answer:
        'I noen nybyggprosjekter kreves en forskuddsinnbetaling på rundt 10 % ved signering av kontrakten. Her kan banken delutbetale denne summen fra finansieringsbeviset ditt, eller du kan betale med din egenkapital. Resten av kjøpesummen betales imidlertid ofte ikke før rett før innflytting, men dette kan variere avhengig av avtalen. Uansett er prisen du bekrefter ved aksept av bud som hovedregel fast, men i enkelte tilfeller kan det forekomme indeksregulering frem til overtakelsen.'
    },
    {
      question: 'Hva er prosessen for å gjøre tilvalg?',
      answer:
        'Ved å kjøpe tidlig i et nybyggprosjekt får du en unik mulighet til å sette ditt eget preg på boligen. Etter kjøpet vil utbygger invitere deg til et tilvalgsmøte, forutsatt at tilvalgsprosessen fortsatt er tilgjengelig. Her kan du gjøre personlige tilpasninger, som å velge detaljer for kjøkken og bad fra en forhåndsdefinert leverandør. Det er lurt å sjekke tilvalgsfristen med megler og avklare om endringer fortsatt kan gjøres dersom byggingen allerede har startet. Merk at de fleste tilvalg innebærer en ekstra kostnad.'
    },
    {
      question: 'Hva skjer etter jeg har kjøpt et nybygg?',
      answer:
        'Etter kjøpet blir du først invitert til et kontraktsmøte med megleren. Hvis tilvalg fortsatt er mulig, får du deretter tilbud om et tilvalgsmøte med utbygger. Dette tilvalgsmøtet kan finne sted en god stund etter kontraktsmøtet, særlig hvis byggeprosjektet ennå ikke er vedtatt. Når overtakelsen nærmer seg, får du ofte anledning til en ferdigbefaring av boligen. Her går du gjennom eiendommen for å identifisere eventuelle feil som må utbedres før overtakelse. Endelig tidspunkt for overtakelse blir ofte varslet noen uker i forveien – ta gjerne kontakt med megler for spesifikasjoner rundt ditt kjøp. I tillegg vil det gjennomføres en ettårsbefaring ett år etter ferdigstillelse, og du har fem års reklamasjonsrett.'
    },
    {
      question: 'Hvordan fungerer mellomfinansiering når man kjøper nybygg?',
      answer:
        'Fordelen med nybygg er at overtakelsen ligger langt frem i tid, noe som gir deg bedre tid til å planlegge salget av din eksisterende bolig. Dersom du likevel har behov for mellomfinansiering, kan dette lånet sikre kjøpet med sikkerhet i din nåværende bolig og gjør det mulig å eie to boliger samtidig. Når salget av din eksisterende bolig er gjennomført, slettes både det gamle lånet og mellomfinansieringen, slik at du sitter igjen med det langsiktige lånet på din nye bolig.'
    },
    {
      question: 'Hvor mye egenkapital må jeg ha?',
      answer:
        'Hovedregelen er at du må ha spart 15 prosent av det boligen koster, men det finnes unntak hvis du er under 34 år. Da er det mulighet for å låne mer enn 85 % av kjøpesummen, så fremt du har betjeningsevne og at banken får tilleggssikkerhet. De strengere kravene i Oslo skyldes hovedstadens sterke boligprisvekst de siste årene. Alle eiendommer utover primærboligen din regnes som sekundærbolig. Det vil si at både en hytte og for eksempel en leilighet kjøpt til barna regnes som sekundærbolig. Bankene har lov til å gjøre unntak fra disse kravene, men det begrenses til maks. 8 % (Oslo) eller 10 % (resten av landet) av bankenes totale innvilgede lån'
    },
    {
      question: 'Hva er et finansieringsbevis, og hva brukes det til?',
      answer:
        'Med et finansieringsbevis fra Nordea har du en bekreftelse på hvor mye du kan låne hos oss slik at du vet hvor mye du kan kjøpe bolig for. Finansieringsbeviset tar utgangspunkt i din nåværende økonomi, verdien på eventuelt eksisterende bolig og din egenkapital, som er pengene du har spart selv. Det er lurt å gjøre seg opp en formening om hvor mye du trenger i lån før du søker om et finansieringsbevis. Hovedregelen er at du kan låne inntil 5 ganger inntekten din og du må kunne tåle en renteoppgang på 3 %-poeng. For at du skal være trygg på at du ikke låner mer enn din økonomi tåler så gjør vi en grundig vurdering av lånestørrelse og din økonomiske situasjon. Søker du om mer enn du kan få innvilget vil vi ta kontakt med deg for å finne en god løsning.'
    },
    {
      question: 'Hva vil det si å være realkausjonist?',
      answer:
        'Skal du stille som realkausjonist er det viktig at du er klar over hva det innebærer, og hvilke konsekvenser dette kan få for din økonomi. Har du inngått en kausjonsavtale i banken, kan du ikke trekke deg. Du bør derfor tenke deg godt om før du sier ja til å være realkausjonist for barnets lån. Som oftest vil banken kreve sikkerhet for lånet ved å etablere pant i boligen barnet kjøper. Det vil også gi en sikkerhet for deg som realkausjonist. Vær imidlertid oppmerksom på at et boligprisfall kan redusere sikkerheten. Dersom barnet ikke betaler tilbake hele eller deler av lånet sitt, vil banken kreve betaling av deg. Det vil derfor være smart å tenke gjennom hva situasjonen vil bli for deg dersom barnet ikke betaler renter og avdrag. Det er viktig at du ikke påtar deg kausjonsansvar hvis du ikke vil klare å betale kausjonsbeløpet. Videre er det viktig å tenke på at kausjoner ofte gjelder for mange år. Både din og barnets økonomi kan endre seg for eksempel ved sykdom, arbeidsledighet, skilsmisse og kraftig renteøkning som kan føre til betalingsproblemer.'
    },
    {
      question: 'Hva er mellomfinansiering?',
      answer:
        'Et mellomfinansieringslån ved kjøp og salg av bolig er i prinsippet det samme som et kortsiktig nedbetalingslån, hvor du kan velge å betale renter og avdrag i perioden, eller bare betale renter. Denne løsningen er aktuell ved boligkjøp dersom du har solgt, men ikke mottatt oppgjør, eller hvis du har kjøpt en ny bolig før du har solgt den du bor i nå.'
    },
    {
      question: 'Hvordan fungerer mellomfinansiering?',
      answer:
        'Mellomfinansiering innebærer at banken låner ut nok penger for at du skal kunne eie to boliger samtidig i en kortere periode. Under mellomfinansieringsperioden har du to parallelle lån - et mellomfinansieringslån som dekker det nye lånebehovet, og det gamle boliglånet.  Du får litt høyere rente på den delen av lånet som er mellomfinansieringslånet. Når pengene fra salg av nåværende bolig kommer inn, vil ditt gamle lån og mellomfinansieringslånet bli slettet, og du vil sitte igjen med ditt nye langsiktige lån.'
    }
  ];

  return (
    <CustomerAdviceStyle>
      <Heading center tag="h3">
        Dette lurer kundene på
      </Heading>
      {questions.map((question, index) => (
        <AreaRowItem
          key={index}
          question={question.question}
          answer={question.answer}
        />
      ))}
    </CustomerAdviceStyle>
  );
};

export default CustomerAdvice;
