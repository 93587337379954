import React from 'react';
import styled from 'styled-components';
import { TrackItem } from '../../ui/playlist/TrackItem';

const Playlist: React.FC = () => {
  const arr: Array<{ id: string; title: string; length: string; url: string }> =
    [
      {
        id: 'track_1',
        title: 'Nyttige råd til budrunden',
        length: '15:21',
        url: 'https://soundcloud.com/user-871377367/pengetreet-budrunden'
      },
      {
        id: 'track_2',
        title: 'Hvordan finne riktig eiendom for deg?',
        length: '11:13',
        url: 'https://soundcloud.com/user-871377367/pengetreet-boligkjopet'
      },
      {
        id: 'track_3',
        title: 'Alt du trenger å vite om nybygg',
        length: '12:27',
        url: 'https://soundcloud.com/user-871377367/pengetreet-nybygg'
      }
    ];

  return (
    <StyledPlaylist>
      {arr.map((item, index) => (
        <TrackItem
          key={item.id}
          number={index + 1}
          title={item.title}
          length={item.length}
          url={item.url}
        />
      ))}
    </StyledPlaylist>
  );
};

export default Playlist;

const StyledPlaylist = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 30px;
  list-style: none;
`;
